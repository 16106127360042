import React from "react"
import { Helmet } from "react-helmet"
import "./src/styles/default.scss"

// Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet defer={false} />
      {element}
    </>
  )
}
